<template>
  <div>
    <b-card v-if="!equipments[0]">
      <h2 style="text-align: center;">
        Hej, nie masz jeszcze żadnych sprzętów.
        <router-link :to="{name: 'Equipment-add'}">
          Dodaj swój pierwszy sprzęt.
        </router-link>
      </h2>
    </b-card>
    <div
      v-for="equipment in equipments"
      :key="equipment"
    >
      <b-card>
        <b-card-header>
          <h2>
            {{ equipment.name }}
          </h2>
        </b-card-header>
        <b-card-body>
          <b-form-input
            v-model="equipment.name"
            placeholder="Nazwa sprzętu"
            required
            style="margin-top: 10px;"
          />
          <b-form-input
            v-model="equipment.quantity"
            placeholder="Ilość sprzętu"
            required
            type="number"
            style="margin-top: 10px;"
          />
        </b-card-body>
        <b-card-footer>
          <b-button
            type="submit"
            variant="success"
            style=" width: 100%;"
            @click="updateEquipment(equipment)"
          >
            Zapisz
          </b-button>

          <b-button
            type="submit"
            variant="danger"
            style=" width: 100%; margin-top: 5px;"
            @click="deleteEquipment(equipment)"
          > Usuń </b-button>
          <div>
            <b-button
              style=" width: 100%; margin-top: 5px;"
              @click="modalShow = !modalShow"
            >Edytuj wymagane usługi</b-button>

            <b-modal
              id="modal-1"
              v-model="modalShow"
              title="Edytuj usługi"
            >
              <div
                v-for="Offer in equipment.offers"
                :key="Offer"
              >
                <b-form-select
                  v-model="Offer.id"
                  :options="alloffers"
                  placeholder="Wybierz usługę"
                  required
                  style="margin-bottom: 10px;"
                />
                <button
                  class="btn btn-danger"
                  style="padding: 5px; margin-bottom: 5px; width: 100%;"
                  @click="deleteOffer()"
                >
                  Usuń usługę
                </button>
              </div>
              <button
                class="btn btn-success"
                style="width: 100%; padding: 10px;"
                @click="newOffer (equipment)"
              >
                Dodaj usługę
              </button>
            </b-modal>
          </div>
        </b-card-footer>
      </b-card>
    </div>
    <router-link :to="{name: 'Equipment-add'}">
      <b-card>
        <b-button
          type="submit"
          variant="primary"
          style=" width: 100%; margin-top: 5px;"
        >
          Dodaj sprzęt
        </b-button>
      </b-card>
    </router-link>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BCard, BCardHeader, BCardBody, BCardFooter, BFormInput, BButton, BFormSelect,
} from 'bootstrap-vue'
import swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BFormInput,
    BButton,
    BFormSelect,
  },
  data() {
    return {
      equipments: [],
      equipment: 'oasdk',
      alloffers: [],
      modalShow: false,
    }
  },
  mounted() {
    document.title = 'Lista sprzętów'
    axios.get(`${process.env.VUE_APP_API_URL}equipment`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then(response => {
      this.equipments = response.data

      for (let i = 0; i < this.equipments.length; i++) {
        this.equipments[i].offers = JSON.parse(this.equipments[i].offers)
      }
    })
      .catch(error => {
        console.log(error)
      })

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}my-offers`, config)
      .then(response => {
        this.alloffers = response.data.success
        for (let i = 0; i < this.alloffers.length; i++) {
          this.alloffers[i].value = this.alloffers[i].id
          this.alloffers[i].text = this.alloffers[i].title
        }
      })
  },
  methods: {
    updateEquipment(equipment) {
      axios.put(`${process.env.VUE_APP_API_URL}equipment/${equipment.id}`, {
        name: equipment.name,
        quantity: equipment.quantity,
        offers: JSON.stringify(equipment.offers),
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(response => {
        swal.fire({
          title: 'Sukces',
          text: 'Zaktualizowano sprzęt',
          type: 'success',
          confirmButtonText: 'Ok',
          icon: 'success',
        })
      })
        .catch(error => {
          console.log(error)
        })
    },
    deleteEquipment(equipment) {
      swal.fire({
        title: 'Czy na pewno chcesz usunąć sprzęt?',
        text: 'Nie będzie można cofnąć tej operacji!',
        type: 'warning',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak, usuń!',
      }).then(result => {
        if (result.value) {
          axios.delete(`${process.env.VUE_APP_API_URL}equipment/${equipment.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }).then(response => {
            swal.fire({
              title: 'Sukces',
              text: 'Usunięto sprzęt',
              type: 'success',
              confirmButtonText: 'Ok',
              icon: 'success',
            })
            this.equipments = this.equipments.filter(e => e.id !== equipment.id)
          })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    newOffer(equipment) {
      equipment.offers.push({

      })
    },
    deleteOffer() {
      this.equipment.offers = []
    },
  },
}
</script>

<style>

</style>
